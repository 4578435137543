<template>
  <div class="form-print-buttons">
    <div class="form-print-button form-print-button--green" v-if="d.signedWithCertificateAt">
      <svg xmlns="http://www.w3.org/2000/svg" width="32.382" height="34.001" viewBox="0 0 32.382 34.001">
        <svg xmlns="http://www.w3.org/2000/svg" width="31.145" height="32.442" viewBox="0 0 31.145 32.442">
          <path
            id="id"
            d="M16.7,2.184a.973.973,0,0,1,1.14,0A26.355,26.355,0,0,0,22.9,4.833a21.3,21.3,0,0,0,7.673,1.709.973.973,0,0,1,.973.973v10.06a10.7,10.7,0,0,1-.073,1.243,5.191,5.191,0,0,0-9.657,2.647v.054a3.894,3.894,0,0,0-3.244,3.839v6.488a3.9,3.9,0,0,0,.121.966,10.806,10.806,0,0,1-1.253.3l-.167.029-.167-.029a18.82,18.82,0,0,1-9.127-5.222C5.209,25.155,3,21.5,3,17.575V7.515a.973.973,0,0,1,.973-.973,21.293,21.293,0,0,0,7.674-1.709A26.353,26.353,0,0,0,16.7,2.184Zm6.411,20.579v-1.3a3.893,3.893,0,0,1,7.786,0v1.3h.649a2.6,2.6,0,0,1,2.6,2.6v6.488a2.6,2.6,0,0,1-2.6,2.6H22.465a2.6,2.6,0,0,1-2.6-2.6V25.358a2.6,2.6,0,0,1,2.6-2.6Zm1.947-1.3v1.3h3.893v-1.3a1.947,1.947,0,0,0-3.893,0ZM28.954,28.6a1.947,1.947,0,1,0-1.947,1.947A1.947,1.947,0,0,0,28.954,28.6Z"
            transform="translate(-3 -2)"
            fill="#fff"
          />
        </svg>
      </svg>
      <p>
        ДОКУМЕНТЪТ Е ЕЛЕКТРОННО ПОДПИСАН ОТ РАБОТОДАТЕЛЯ: {{ d.receiver.name }} НА
        {{ d.signedWithCertificateAt | formatDateFull }}
      </p>
    </div>
    <template v-if="d.userDecision && d.finalized">
      <div class="form-print-button form-print-button--blue">
        <svg xmlns="http://www.w3.org/2000/svg" width="32.382" height="34.001" viewBox="0 0 32.382 34.001">
          <path
            id="user"
            d="M3,8.072A1.214,1.214,0,0,1,4.214,6.857,20.822,20.822,0,0,0,16.843,2.243a1.214,1.214,0,0,1,1.457,0A20.822,20.822,0,0,0,30.93,6.857a1.214,1.214,0,0,1,1.214,1.214v8.5q0,.821-.066,1.613A10.525,10.525,0,0,0,18.591,34.066q-.284.117-.574.232a1.214,1.214,0,0,1-.89,0C7.789,30.619,3,24.669,3,16.572ZM35.382,27.1A8.905,8.905,0,1,0,26.477,36,8.9,8.9,0,0,0,35.382,27.1Zm-3.475-3.811a.81.81,0,0,1,0,1.145L25.43,30.907a.81.81,0,0,1-1.145,0l-3.238-3.238a.81.81,0,1,1,1.145-1.145l2.666,2.666,5.9-5.9A.81.81,0,0,1,31.907,23.285Z"
            transform="translate(-3 -2)"
            fill="#fff"
          />
        </svg>
        <p>
          ДОКУМЕНТЪТ Е ПРИЕТ ОТ СЛУЖИТЕЛЯ: {{ d.form.user.name }} НА
          {{ d.finalizedAt | formatDateFull }}
        </p>
      </div>
      <div class="form-print-button form-print-button--blue form-print-button--big">
        <svg xmlns="http://www.w3.org/2000/svg" width="32.516" height="35.938" viewBox="0 0 32.516 35.938">
          <g id="Group_64307" data-name="Group 64307" transform="translate(-1 -2)">
            <path
              id="Path_39640"
              data-name="Path 39640"
              d="M17.691,2V12.268a3.423,3.423,0,0,0,3.423,3.423H31.382V32.8a3.423,3.423,0,0,1-3.423,3.423H16.305A11.127,11.127,0,0,0,4,18.255V5.423A3.423,3.423,0,0,1,7.423,2Z"
              transform="translate(2.134 0)"
              fill="#fff"
            />
            <path
              id="Path_39641"
              data-name="Path 39641"
              d="M13.5,2.5v9.412a.856.856,0,0,0,.856.856h9.412Z"
              transform="translate(8.892 0.356)"
              fill="#fff"
            />
            <path
              id="Path_39642"
              data-name="Path 39642"
              d="M19.825,21.412A9.412,9.412,0,1,1,10.412,12,9.412,9.412,0,0,1,19.825,21.412Zm-3.673-4.028a.856.856,0,0,0-1.21,0L8.7,23.625,5.883,20.807a.856.856,0,0,0-1.21,1.21L8.1,25.44a.856.856,0,0,0,1.21,0l6.845-6.845A.856.856,0,0,0,16.152,17.385Z"
              transform="translate(0 7.113)"
              fill="#fff"
            />
          </g>
        </svg>
        <p>
          СЛУЖИТЕЛЯТ СЕ Е ЗАПОЗНАЛ/ПРИЕЛ ДЛЪЖНОСТНАТА ХАРАКТЕРИСТИКА, ПРИКАЧЕНА КЪМ ДС: {{ d.form.user.name }} НА
          {{ d.finalizedAt | formatDateFull }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['d'],
};
</script>

<style lang="scss" scoped>
.form-print-buttons {
  display: none;
}

@media print {
  .form-print-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .form-print-button {
      align-items: center;
      border-radius: 4px;
      display: flex;
      gap: 10px;
      color: #fff;
      padding: 1rem;
      width: calc(50% - 5px);

      &.form-print-button--green {
        background: #00840d;
      }
      &.form-print-button--blue {
        background: #008fec;
      }
      &.form-print-button--big {
        background: #9B9B9B;
        flex: 1;
      }

      p {
        text-transform: uppercase;
      }
    }
  }
}
</style>
